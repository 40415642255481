.life-coach-session-popup .modal-dialog {
    max-width: 100%;
}

.life-coach-session-popup .modal-content {
    background-color: var(--color__darkGreen);
    /* width: 90%!important; */
}

.life-coach-session-popup .modal-body {
    padding: 2rem;
}

.life-coach-session-popup .modal-header {
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.life-coach-session-popup .modal-title {
    position: absolute;
    color: var(--color__yellow);
}

.life-coach-session-popup .modal-body .btn-yellow {
    border-radius: 2rem!important;
    padding: 1rem 5rem 1rem 5rem!important;
    font-weight: bold;
    background-color: var(--color__darkGreen);
    border: 0.1rem solid var(--color__darkGreen);
    color: white;
    font-family: "Open Sans", sans-serif;
}

.life-coach-session-popup .modal-body .btn-yellow:hover {
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__darkGreen);
}

.life-coach-session-popup .continue-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.life-coach-session-popup .continue-btn {
    border-radius: 2rem!important;
    padding: 0.8rem 3rem 0.8rem 3rem!important;
    font-size: 2rem;
    font-weight: bold;
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-left: 2rem;
}

.life-coach-session-popup .continue-btn:hover {
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__darkGreen);
}

@media screen and (max-width: 991px) {
    .life-coach-session-popup .continue-btn {
        font-size: 1.5rem;
    }
    .life-coach-session-popup .continue-btn-div {
        height: 36px;
    }
}