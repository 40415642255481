.life-coach-session-component .session-ended-component {
    margin: 10px 0;
    padding: 2% 15% 2% 15%;
}

.life-coach-session-component .session-ended-component-bot {
    background-color: var(--color__darkGreen);
}

.life-coach-session-component .session-ended-component-user {
    background-color: #1c1c1c;
}

.life-coach-session-component .session-ended-component .bot-logo {
    width: 60px;
    height: 60px;
}

.life-coach-session-component .session-ended-component-user .user-name {
    text-transform: uppercase;
    background-color: #ff4d1d;
    font-size: 3rem;
    padding-top: 0.5rem;
    font-weight: bold;
    height: 6rem;
    width: 6rem;
    text-align: center;
    min-width: 6rem;
}

@media screen and (max-width:767px) {
    .life-coach-session-component .session-ended-component {
        padding: 1% 5% 1% 5%;
    }
    .life-coach-session-component .session-ended-component .bot-logo {
        width: 30px;
        height: 30px;
        margin-top: 1rem;
    }
    .life-coach-session-component .session-ended-component-user .user-name {
        font-size: 2rem;
        width: 30px;
        height: 30px;
        margin-top: 1rem;
    }
}

.life-coach-session-component .session-ended-component .user .message-content {
    background-color: #1c1c1c;
    color: white;
    display: inline-block;
    padding: 8px 15px;
    font-size: 2.5rem!important;
    white-space: 'break-spaces'
}

.life-coach-session-component .session-ended-component .bot .message-content {
    background-color: var(--color__darkGreen);
    color: lightgrey;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    font-size: 2.5rem!important;
    white-space: break-spaces;
}

.life-coach-session-component .session-ended-component .bot .message-content a {
    text-decoration: underline;
    font-size: 2.5rem;
}

.life-coach-session-component .session-ended-component .message {
    display: flex;
}

.life-coach-session-component .session-ended-component .continue-btn {
    background-color: var(--color__yellow);
    border-color: var(--color__yellow);
}

.life-coach-session-component .session-ended-component ol {
    list-style-type: decimal;
    padding-left: 2rem;
}

.life-coach-session-component .session-ended-component p,
.life-coach-session-component .session-ended-component ol li {
    color: white;
    font-size: 2.5rem!important;
    white-space: 'break-spaces'
}