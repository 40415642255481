.signup-step-container .steps {
    position: relative;
    display: block;
    width: 100%;
}

.signup-step-container .steps>ul {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin: 0;
    padding: 0;
    list-style: none;
}

.signup-step-container .steps>ul>li {
    display: table-cell;
    width: auto;
    vertical-align: top;
    text-align: center;
    position: relative;
}

.signup-step-container .steps>ul>li.current>a {
    color: #333333;
    cursor: default;
}

.signup-step-container .steps>ul>li a {
    position: relative;
    padding-top: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
}

.signup-step-container .steps .current-info {
    position: absolute;
    left: -99999px;
}

.signup-step-container .steps>ul>li.current .step,
.signup-step-container .steps>ul>li.done .step {
    border-color: #11393E;
    background-color: #fff;
    color: #11393E;
}

.signup-step-container .steps>ul>li.done .step {
    background-color: #11393E;
    border-color: #11393E;
    color: #fff;
}

.signup-step-container .steps .step {
    width: 45px;
    height: 45px;
    line-height: 35px;
    border: 5px solid #F5F7FA;
    font-size: 1.3rem;
    border-radius: 50%;
}

.signup-step-container .steps .step {
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -24px;
    z-index: 10;
    text-align: center;
}

.signup-step-container .steps>ul>li:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 9;
    left: 0;
}

.signup-step-container .steps>ul>li:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 9;
    right: 0;
}

.signup-step-container .steps .current:not(.first):before,
.signup-step-container .steps .done:not(.first):before,
.signup-step-container .steps .done:after {
    top: 30px;
    width: 50%;
    height: 5px;
    background-color: #11393E;
}