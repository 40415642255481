footer {
    background-color: var(--color__darkGreen);
    padding: 2.5rem;
    position: sticky;
}

footer ul li .nav-link {
    font-size: 1.3rem;
    color: white;
}

footer .version-link {
    font-size: 1.4rem;
    color: white;
    text-decoration: underline;
}

footer ul li .nav-link:hover,
footer .version-link:hover {
    color: var(--color__yellow);
}

footer p {
    font-size: 1.4rem;
    color: white;
}

footer img {
    width: 80px;
}

@media screen and (max-width: 991px) {
    footer ul li .nav-link {
        font-size: 1.2rem;
    }
    footer p {
        font-size: 1.2rem;
    }
    footer img {
        width: 60px;
    }
    footer .logo-link {
        margin: auto;
        margin-top: 1rem;
    }
}