.menu-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.menu-nav-link {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu-item {
  display: flex;
  gap: 1.2rem;
  padding: 10px 0px 10px 15%;
}

.btn-menu-item {
  padding: 10px 0px 10px 15%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: none;
}

.menu-item p {
  font-size: 16px;
}

.menu-item.active {
  background-color: #153033;
}

.menu-user {
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.page-content {
  background-color: var(--color__white);
  flex: 1;
  position: relative;
  height: 100%;
}

.notification-title {
  font-size: 18px;
  color: var(--color__black);
  margin-bottom: 5px;
}

.notification-content {
  font-size: 14px;
  color: gray;
}
