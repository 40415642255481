.verify-container .form-title {
    margin-bottom: 3rem;
}

.verify-container {
    background-color: var(--color__darkGreen);
    min-height: 100vh;
}

.verify-container .side-form {
    height: 100%;
    padding: 10rem 0;
}

.verify-container .form-group+.form-group {
    margin-top: 2rem;
}

.verify-container .form-group label {
    margin-bottom: 0.5rem;
}

.verify-container .custom-check label {
    padding-left: 2.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.verify-container .custom-check label::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    background-color: var(--color__white);
    border-radius: 0.3rem;
    border: 0.1rem solid var(--color__darkGreen);
    transition: all 0.3s;
}

.verify-container .custom-check label::after {
    content: "";
    position: absolute;
    left: 0.4rem;
    width: 1.2rem;
    height: 0.6rem;
    border-bottom: 0.2rem solid var(--color__white);
    border-left: 0.2rem solid var(--color__white);
    transform: rotate(-45deg);
    margin-bottom: 0.2rem;
    opacity: 0;
    transition: all 0.3s;
}

.verify-container .custom-check input {
    display: none;
}

.verify-container .custom-check input:checked~label::before {
    background-color: var(--color__darkGreen);
}

.verify-container .custom-check input:checked~label::after {
    opacity: 1;
}

.verify-container button[type=submit] {
    width: 100%;
    border: 0;
    background-color: var(--color__yellow);
    padding: 1rem 0;
    border-radius: 0.5rem;
}

.verify-container .forgot-password {
    margin-left: auto;
}

.verify-container .forgot-password a {
    cursor: pointer;
}

.verify-container .password-container {
    position: relative;
}

.verify-container .password-container .eye-img {
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    cursor: pointer;
    width: 2rem;
}

.verify-container .btn-secondary {
    color: white;
    border: 0.1rem solid var(--color__darkGreen);
    background-color: var(--color__darkGreen);
}

.verify-container .btn-secondary:hover {
    background-color: #123d42;
}