.life-coach-session-component .session-bot-processing-component {
    margin: 10px 0;
    padding: 3% 15% 3% 15%;
}

.life-coach-session-component .session-bot-processing-component {
    background-color: var(--color__darkGreen);
}

.life-coach-session-component .session-bot-processing-component .bot-logo {
    width: 60px;
    height: 60px;
}

@media screen and (max-width:767px) {
    .life-coach-session-component .session-bot-processing-component {
        padding: 1% 5% 1% 5%;
    }
    .life-coach-session-component .session-bot-processing-component .bot-logo {
        width: 30px;
        height: 30px;
    }
}

.life-coach-session-component .session-bot-processing-component .dots {
    background-color: var(--color__darkGreen);
    color: lightgrey;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    font-size: 2.8rem;
    white-space: break-spaces;
}

.life-coach-session-component .session-bot-processing-component .dots {
    display: inline-block;
}

.life-coach-session-component .session-bot-processing-component .dots span {
    opacity: 0;
    animation: dotFade 3s infinite;
}

.life-coach-session-component .session-bot-processing-component .dots span:nth-child(1) {
    animation-delay: 1s;
}

.life-coach-session-component .session-bot-processing-component .dots span:nth-child(2) {
    animation-delay: 2s;
}

.life-coach-session-component .session-bot-processing-component .dots span:nth-child(3) {
    animation-delay: 3s;
}

@keyframes dotFade {
    0%,
    33%,
    100% {
        opacity: 0;
    }
    34%,
    66% {
        opacity: 1;
    }
}