.badge-alert-popup .modal-body {
    padding: 2rem;
}

.badge-alert-popup .modal-header {
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.badge-alert-popup .modal-title {
    position: absolute;
}