@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color__black: #000000;
    --color__offBlack: #1C1C1C;
    --color__white: #FFFFFF;
    --color__darkGreen: #11393E;
    --color__yellow: #d7a03e;
    --color__pink: #FF4D1D;
}

html {
    font-size: 56%;
    -webkit-text-size-adjust: 100%;
}

@media screen and (min-width: 768px) {
    html {
        font-size: 60%;
    }
}

@media screen and (min-width: 992px) {
    html {
        font-size: 62.5%;
    }
}

body {
    font-size: 1.6rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
}

button,
input,
optgroup,
select,
textarea,
option {
    font-family: "Roboto"!important;
}

p {
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li,
ol li {
    font-size: 1.6rem;
}

a {
    color: inherit;
    text-decoration: none;
    font-size: 1.6rem;
    display: inline-block;
}

a:hover {
    color: inherit;
    text-decoration: none;
}


/*-------------------------------------------------
      [ ## Heading ]
  ---------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-family: "Gotham Rounded";
    font-weight: bold;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
    color: inherit;
    text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover {
    color: inherit;
    text-decoration: none;
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 4rem;
}

@media screen and (min-width: 1200px) {
    h2 {
        font-size: 5rem;
    }
}

h3 {
    font-size: 3rem;
}

@media screen and (min-width: 1200px) {
    h3 {
        font-size: 4rem;
    }
}

h4 {
    font-size: 3.5rem;
}

h5 {
    font-size: 2.8rem;
}

h6 {
    font-size: 2rem;
}


/*-------------------------------------------------
      [ ## Fields ]
  ---------------------------------------------------*/

.login-register input[type=text],
.login-register input[type=email],
.login-register input[type=url],
.login-register input[type=password],
.login-register input[type=search] {
    font-size: 1.6rem;
    height: 5rem;
    padding: 1rem 1.5rem;
    background-color: var(--color__white);
}

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--color__darkGreen);
}

input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=search] {
    -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    box-shadow: 0 0 0px 1000px rgba(var(--color__black, 0) inset);
    -webkit-box-shadow: 0 0 0px 1000px rgba(var(--color__black, 0) inset);
    -webkit-text-fill-color: var(--color__body) !important;
}

input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px rgba(var(--color__black, 0) inset);
    -webkit-box-shadow: 0 0 0px 1000px rgba(var(--color__black, 0) inset);
    -webkit-text-fill-color: var(--color__body) !important;
}

textarea.form-control {
    display: block;
    width: 100%;
    display: block;
    min-height: 150px;
    padding-top: 0px;
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
}

textarea.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--color__darkGreen);
}

.form-floating>.form-control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
}

 ::-webkit-input-placeholder {
    color: var(--color__body);
}

.widget_newsletter::-webkit-input-placeholder {
    color: #808080;
}

 :-moz-placeholder {
    color: var(--color__body);
    opacity: 1;
}

 ::-moz-placeholder {
    color: var(--color__body);
    opacity: 1;
}

 :-ms-input-placeholder {
    color: var(--color__body);
}


/**
   * Hide element while making it readable for screen readers
   */

.figure {
    display: block;
}

.text-pink {
    color: var(--color__pink);
}

.section-padding {
    padding: 6rem 0;
}

@media screen and (min-width: 576px) {
    .section-padding {
        padding: 10rem 0;
    }
}

@media screen and (min-width: 992px) {
    .section-padding {
        padding: 12rem 0;
    }
}

.section-margin {
    margin: 6rem 0;
}

@media screen and (min-width: 576px) {
    .section-margin {
        margin: 10rem 0;
    }
}

@media screen and (min-width: 992px) {
    .section-margin {
        margin: 12rem 0;
    }
}

.section-title.text-center {
    margin-bottom: 4rem;
}

.section-title.text-center .title {
    font-weight: 600;
    font-size: 3rem;
}

@media screen and (min-width: 768px) {
    .section-title.text-center .title {
        font-size: 5rem;
    }
}

.section-title.text-center p {
    max-width: 50rem;
    margin: 0 auto;
}

.quize_dashboard {
    background-color: #F1F4F7;
    min-height: 100vh;
}

@media screen and (min-width: 992px) {
    .quize_dashboard {
        position: fixed;
        inset: 0;
        overflow-y: scroll;
    }
}

.quize_dashboard .left-sidenav {
    width: 300px;
    background-color: var(--color__darkGreen);
    min-height: 100vh;
    transition: all 0.3s;
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 1001;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 991px) {
    .quize_dashboard .left-sidenav {
        left: -300px;
    }
    .quize_dashboard .left-sidenav.sidemenu-open {
        left: 0;
    }
    .quize_dashboard .left-sidenav .left-sidenav-close {
        position: absolute;
        height: 2rem!important;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }
}

.quize_dashboard .left-sidenav::-webkit-scrollbar {
    width: 0.5rem;
}

.quize_dashboard .left-sidenav::-webkit-scrollbar-track {
    background: transparent;
}

.quize_dashboard .left-sidenav::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0.5rem;
    border: 0.3rem solid rgba(255, 255, 255, 0.2);
}

.quize_dashboard .left-sidenav .brand {
    padding: 2rem 3rem;
}

.quize_dashboard .left-sidenav .brand img {
    height: 7.5rem;
}

.quize_dashboard .left-sidenav .menu-content {
    padding: 0;
    color: var(--color__white);
    text-align: center;
}

.quize_dashboard .left-sidenav .menu-content a.assessment-tool {
    text-decoration-line: underline;
    text-decoration-color: white;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    text-underline-offset: 2rem;
}

.quize_dashboard .left-sidenav .menu-content a.assessment-tool:hover {
    color: var(--color__white);
    cursor: default;
}

.quize_dashboard .left-sidenav .widget+.widget {
    margin-top: 5rem;
}

.quize_dashboard .left-sidenav .widget .widget-title,
.quize_dashboard .page-wrapper .widget .widget-title {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid var(--color__white);
    padding-bottom: 0.5rem;
}

.quize_dashboard .left-sidenav .widget ul:not(.pagination) li+li,
.quize_dashboard .page-wrapper .widget ul:not(.pagination) li+li {
    margin-top: 1.5rem;
}

.quize_dashboard .left-sidenav .widget ul:not(.pagination) li a {
    font-size: 1.6rem;
}

.category-name a:hover,
.quize_dashboard .left-sidenav .widget ul li a:hover,
.quize_dashboard .left-sidenav .completed_tasks.widget ul li:hover,
.quize_dashboard .page-wrapper .completed_tasks.widget ul li:hover {
    color: var(--color__yellow);
    cursor: pointer;
}

.quize_dashboard .left-sidenav .widget ul .active a,
.quize_dashboard .left-sidenav .widget ul .active a svg,
.quize_dashboard .left-sidenav .widget ul .active a svg path {
    color: var(--color__yellow)!important;
    fill: var(--color__yellow)!important;
}

.quize_dashboard .left-sidenav .widget ul li a:hover svg,
.quize_dashboard .left-sidenav .completed_tasks.widget ul li a:hover svg:hover path,
.quize_dashboard .page-wrapper .completed_tasks.widget ul li a:hover svg:hover path {
    fill: var(--color__yellow);
}

.quize_dashboard .left-sidenav .widget.completed_tasks ul li svg,
.quize_dashboard .page-wrapper .widget.completed_tasks ul li svg {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
}

.quize_dashboard .left-sidenav .widget.completed_tasks ul li:hover svg,
.quize_dashboard .page-wrapper .widget.completed_tasks ul li:hover svg,
.quize_dashboard .page-wrapper .widget.completed_tasks ul li:hover img {
    opacity: 1;
    visibility: visible;
}

.quize_dashboard .page-wrapper .completed_tasks.widget ul li svg path {
    fill: var(--color__darkGreen);
}

.quize_dashboard .page-wrapper .widget .widget-title {
    color: var(--color__darkGreen);
    border-bottom: 0.1rem solid #8ba1a454;
    text-align: center;
}

.quize_dashboard .page-wrapper .completed_tasks.widget {
    padding: 2.5rem 0.5rem 2.5rem 2.5rem;
    border: 2px solid var(--color__darkGreen);
    margin-left: 3rem;
}

@media screen and (max-width:767px) {
    .quize_dashboard .page-wrapper .completed_tasks.widget {
        margin-left: 0;
        margin-top: 4rem;
        padding: 2.5rem 0.5rem 2.5rem 2.5rem;
    }
}

.quize_dashboard .page-wrapper .completed_tasks li {
    display: block !important;
    text-align: center;
}

.quize_dashboard .page-wrapper .completed_tasks li a {
    margin-left: 5px;
}

.quize_dashboard .topbar-container {
    background-color: var(--color__white);
    padding: 5px 10px;
    display: none;
    height: 50px;
    background: var(--color__darkGreen);
    padding: 5px 20px;
}

.quize_dashboard .topbar-container .toggle-btn {
    border: none;
    background-color: transparent;
}

.quize_dashboard .topbar-container .toggle-btn svg path {
    fill: white;
}

.quize_dashboard .topbar-container .logo-mobile {
    float: left;
}


.quize_dashboard .topbar-container .logo-mobile img {
    width: 100px;
    height: 38px;
}

@media screen and (max-width: 991px) {
    .quize_dashboard .topbar-container {
        display: flex;
        justify-content: space-between;
    }
}

.quize_dashboard .topbar .navbar-custom {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quize_dashboard .topbar .btn-rounded-secondary {
    color: var(--color__darkGreen);
}

.quize_dashboard .topbar li.top-menu a {
    color: var(--color__white)!important;
    border-color: var(--color__white) !important;
    margin-right: 2rem;
    transition: .3s;
    margin-bottom: 1rem;
}

.quize_dashboard .topbar li.top-menu a.active {
    text-decoration-line: underline;
    text-decoration-color: white;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.8rem;
}


/* @media only screen and (max-width: 768px) {
    .quize_dashboard .topbar li.top-menu a.active {
        text-underline-offset: 10px;
    }
    
} */

.quize_dashboard .topbar li.top-menu a:hover {
    color: var(--color__white)!important;
    border-color: var(--color__body) !important;
}

.quize_dashboard .topbar .sidebar-toggler {
    background-color: transparent;
    border: 0;
    padding: 0;
}

.quize_dashboard .topbar-mobile {
    width: 100%;
    background-color: var(--color__white)!important;
    position: sticky;
    border-left: 0.1rem solid var(--color__white);
    transition: all 0.3s;
    z-index: 900;
    overflow-x: auto;
}

.quize_dashboard .topbar-mobile .mobile-navbar-custom {
    position: relative;
}

.quize_dashboard .topbar-mobile nav {
    display: flex;
    position: relative;
    z-index: 0;
}

.quize_dashboard .topbar-mobile ul {
    display: flex!important;
}

.quize_dashboard .topbar-mobile li {
    flex-shrink: 0;
    z-index: 0;
}

.quize_dashboard .topbar-mobile li a {
    display: inline-flex;
    flex-shrink: 0;
    color: var(--color__darkGreen)!important;
    transition: .3s;
    padding: 1.7rem 1.2rem;
    font-weight: bold;
}

.quize_dashboard .topbar-mobile li a.active {
    border-bottom: 4px solid var(--color__darkGreen);
}

.quize_dashboard .page-wrapper {
    flex: 1;
    padding: 0;
    display: flex;
    margin-left: 300px;
    transition: all 0.3s;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.quize_dashboard .page-content .action-plan-loader {
    width: 20vw
}

@media screen and (max-width: 991px) {
    .quize_dashboard .page-wrapper {
        margin-left: 0;
    }
    .quize_dashboard > .page-content {
        margin-top: 0rem;
        padding: 0rem 0rem 3rem 0rem;
    }

    .quize_dashboard > .page-content .action-plan-loader {
        width: 60vw
    }
}

.quize_dashboard .page-content .list-disc {
    list-style-type: disc;
    margin-left: 2rem;
}

.quize_dashboard .page-content .list-disc li+li {
    margin-top: 1rem;
}

.swal2-popup {
    font-size: 1.5rem !important;
}

.google-login-button div>* {
    text-align: center!important;
}

.completed_tasks .form-select,
.completed_tasks .form-control,
.completed_tasks input {
    height: 3rem!important;
    font-size: 1.5rem!important;
}

.completed_tasks .apply-button {
    background-color: var(--color__yellow);
    border-color: var(--color__yellow);
    font-size: 1.3rem;
    color: var(--color__darkGreen);
}

.completed_tasks .apply-button:hover {
    background-color: var(--color__white);
    border-color: var(--color__darkGreen);
    color: var(--color__darkGreen);
}

.completed_tasks .react-datepicker__day-name {
    width: 1.7rem!important;
    line-height: 2.7rem!important;
}

.completed_tasks .react-datepicker {
    font-size: 1.2rem!important;
}

@media screen and (max-width: 991px) {
    .quize_dashboard .page-wrapper .completed_tasks ul li span {
        font-size: 1.2rem;
    }
    .quize_dashboard .page-wrapper .completed_tasks ul li a {
        font-size: 1.2rem;
        margin-left: 2px;
    }
    .quize_dashboard .page-wrapper .completed_tasks ul li a svg {
        width: 15px;
    }
}

.btn-secondary-alt {
    background-color: var(--color__white);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__darkGreen);
    padding: 1rem 2rem;
    transition: all 0.2s;
    border-radius: 5rem;
}

@media screen and (min-width: 768px) {
    .btn-secondary-alt {
        padding: 1.4rem 3rem;
    }
}

.btn-secondary-alt svg {
    transition: all 0.2s;
}

.btn-secondary-alt:hover {
    background-color: var(--color__yellow);
    color: var(--color__white);
    border-color: var(--color__yellow);
}

.btn-secondary-alt:hover svg {
    fill: var(--color__white);
    transform: rotate(-45deg);
    margin-left: 5px;
}

#terms-and-privacy .btn-secondary {
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__black);
    transition: all 0.2s;
}

#terms-and-privacy .btn-secondary:hover {
    color: var(--color__white);
}

#terms-and-privacy .btn-secondary-alt {
    background-color: var(--color__darkGreen);
    border: 0.1rem solid var(--color__darkGreen);
    color: var(--color__white);
    border-radius: 0rem;
    transition: all 0.2s;
}

@media screen and (min-width: 768px) {
    #terms-and-privacy .btn-secondary {
        padding: 0.4rem 3rem;
    }
    #terms-and-privacy .btn-secondary-alt {
        padding: 0.4rem 3rem;
    }
}

#terms-and-privacy .btn-secondary-alt:hover {
    background-color: #EFE6D6;
    color: var(--color__darkGreen);
    border-color: #EFE6D6;
}

#terms-and-privacy .buttons {
    display: inline-flex;
    align-items: center;
    gap: 2rem;
}

#terms-and-privacy a {
    text-decoration: underline;
}

#terms-and-privacy .form-check {
    text-align: start;
}

.hero .hero-content .buttons {
    display: inline-flex;
    align-items: center;
    gap: 2rem;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
.btn-yellow {
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__darkGreen);
    font-family: "Open Sans", sans-serif;
}

.btn-yellow:hover {
    background-color: var(--color__darkGreen);
    color: var(--color__white);
    border-color: var(--color__darkGreen);
}

.btn-gray {
    background-color: transparent;
    border: none;
    color: var(--color__darkGreen);
    font-family: "Open Sans", sans-serif;
}

.btn-gray:hover {
    background-color: #EFE6D6;
    color: var(--color__darkGreen);
    border-color: #EFE6D6;
}

.btn-gray:disabled {
    background-color: gray;
    border-color: gray;
}

.btn-danger {
    background-color: transparent;
    border-color: #cf222e;
    color: #cf222e;
    font-family: "Open Sans", sans-serif;
}

.btn-danger:hover {
    background-color: #cf222e;
    color: #EFE6D6;
    border-color: #cf222e;
}

#root>div>div.page-wrapper.sidemenu-open>div.page-content>div>div>form>div>div.sd-title.sd-container-modern__title>div.sd-header__text>h3 {
    text-align: center;
}

.swal2-popup {
    font-family: "Roboto"!important;
}

.swal2-popup a {
    color: var(--bs-link-color);
    text-decoration: underline;
    font-size: 1.5rem;
}

.swal2-popup ul li {
    font-family: "Roboto"!important;
    font-size: 1.5rem;
    padding-top: 0.3rem;
    padding-left: 0.3rem;
    line-height: 1.5;
}

.swal2-html-container {
    line-height: 1.4!important;
}


/* 
.quize_dashboard .page-wrapper .completed_tasks .pagination li {
    display: flex!important;
} */

.swal2-styled.swal2-confirm {
    color: #11393e!important;
    background-color: rgb(215, 160, 62)!important;
    font-weight: bold;
}

.swal2-styled.swal2-cancel,
.swal2-styled.swal2-deny {
    color: #11393e!important;
    background-color: transparent!important;
    font-weight: bold;
}

.swal2-styled.swal2-confirm:hover {
    background-color: var(--color__darkGreen)!important;
    color: var(--color__white)!important;
    border-color: var(--color__darkGreen)!important;
}

.swal2-styled.swal2-cancel {
    color: #11393e!important;
    background-color: transparent!important;
    font-weight: bold;
}

.swal2-styled.swal2-cancel:hover,
.swal2-styled.swal2-deny:hover {
    background-color: #EFE6D6!important;
    border-color: #EFE6D6!important;
}

.mandatory-label:after {
    content: "*";
    color: red;
}

.sd-item--disabled .sd-item__control-label {
    opacity: 1!important
}

.sd-radio--checked.sd-radio--disabled .sd-radio__decorator:after {
    background-color: #3b3b3b!important;
}

.sd-page .sd-page__description {
    color: black!important;
}

.sd-container-modern {
    margin-bottom: 0px!important;
}

.sd-container-modern .sd-html a {
    color: #0d6efd!important;
}

.assessment-result-container .close-btn {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem!important;
}

.modal-title {
    font-size: 2rem!important;
}

#session-completed-tooltip {
    z-index: 1000;
}

#session-completed-tooltip .tooltip-inner {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 1rem 2rem 1rem 2rem;
    background-color: rgba(255, 100, 100, 1);
    max-width: 50%;
}

#session-completed-tooltip {
    opacity: 1;
}

.modal-header {
    position: relative; 
    border-bottom: none;
  }
  
.modal-header .modal-title {
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    font-family: "Gotham Rounded";
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.modal-header .btn-close {
    position: absolute;
    top: 45%;
    right: 15px;
}
  