.life-coach-session-component .session-chat-input-component {
    padding: 10px;
    background-color: var(--color__darkGreen);
    position: relative;
    padding: 1% 15% 1% 15%;
}

.life-coach-session-component .session-chat-input-component .chat-textarea {
    width: 100%;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    outline: none;
    font-size: 1.8rem;
    resize: none;
    transition: border 0.2s;
    /* ... other styles ... */
    overflow-y: hidden;
    /* Hide vertical scrollbar */
    height: auto;
    /* Start off with automatic height */
    min-height: 60px;
    /* Minimum height */
    max-height: 200px;
    padding-right: 60px;
    background: #b3b2b4;
    /* Set a max height if you want */
    /* Remove the textarea's default styles on focus */
    &:focus {
        box-shadow: none;
    }
}

@media screen and (max-width:767px) {
    .life-coach-session-component .session-chat-input-component .chat-textarea {
        padding-right: 50px;
    }
}

.life-coach-session-component .session-chat-input-component textarea {
    color: black;
}

.life-coach-session-component .session-chat-input-component textarea ::placeholder {
    color: darkgray;
}

.life-coach-session-component .session-chat-input-component .send-button {
    position: absolute;
    right: 16%;
    bottom: 35px;
    color: black;
    border: none;
    opacity: 0.8;
    cursor: pointer;
    width: 35px;
    height: 35px;
    transition: opacity 0.2s;
    padding: 1rem;
    background-color: #C1FF72;
    border-radius: 1rem;
}

.life-coach-session-component .session-chat-input-component .send-button:hover {
    opacity: 1;
}

.life-coach-session-component .session-chat-input-component .send-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: transparent!important;
}