@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('Roboto-Bold.woff2') format('woff2'), url('Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'), url('Roboto-Medium.woff2') format('woff2'), url('Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('Roboto-Regular.woff2') format('woff2'), url('Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'), url('Roboto-Light.woff2') format('woff2'), url('Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: local('Gotham Rounded Bold'), local('GothamRounded-Bold'), url('GothamRounded-Bold.woff2') format('woff2'), url('GothamRounded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: local('Gotham Rounded Medium'), local('GothamRounded-Medium'), url('GothamRounded-Medium.woff2') format('woff2'), url('GothamRounded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: local('Gotham Rounded Light'), local('GothamRounded-Light'), url('GothamRounded-Light.woff2') format('woff2'), url('GothamRounded-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded Book';
    src: local('Gotham Rounded Book'), local('GothamRounded-Book'), url('GothamRounded-Book.woff2') format('woff2'), url('GothamRounded-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}