.life-coach-session-component .session-tag-component {
    cursor: pointer;
    display: inline-block;
    border: 2px solid white;
    border-radius: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    text-align: center;
    color: white;
    white-space: nowrap;
}

.life-coach-session-component .session-tag-component:hover {
    background-color: #1c1c1c;
}

.life-coach-session-component .session-tag-component.active {
    background-color: #1c1c1c;
}