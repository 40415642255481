.life-coach-session-component .session-message-component {
    margin: 10px 0;
    padding: 1.5% 15% 1.5% 15%;
}

.life-coach-session-component .session-message-component-bot {
    background-color: var(--color__darkGreen);
}

.life-coach-session-component .session-message-component-user {
    background-color: #1c1c1c;
}

.life-coach-session-component .session-message-component .bot-logo {
    width: 60px;
    height: 60px;
}

.life-coach-session-component .session-message-component-user .user-name {
    text-transform: uppercase;
    background-color: #ff4d1d;
    font-size: 3rem;
    padding-top: 0.5rem;
    font-weight: bold;
    height: 6rem;
    width: 6rem;
    text-align: center;
    min-width: 6rem;
}

.life-coach-session-component .session-message-component-bot .empty-space {
    text-transform: uppercase;
    background-color: transparent;
    font-size: 3rem;
    padding-top: 0.5rem;
    font-weight: bold;
    height: 6rem;
    width: 6rem;
    text-align: center;
    min-width: 6rem;
}

@media screen and (max-width:767px) {
    .life-coach-session-component .session-message-component {
        padding: 1% 5% 1% 5%;
    }
    .life-coach-session-component .session-message-component .bot-logo {
        width: 30px;
        height: 30px;
        margin-top: 1rem;
    }
    .life-coach-session-component .session-message-component-user .user-name {
        font-size: 2rem;
        width: 30px;
        height: 30px;
        margin-top: 1rem;
    }
}

.life-coach-session-component .session-message-component .user .message-content {
    background-color: #1c1c1c;
    color: white;
    display: inline-block;
    padding: 8px 15px;
    font-size: 2.3rem;
    white-space: 'break-spaces'
}

.life-coach-session-component .session-message-component .bot .message-content,
.life-coach-session-component .session-message-component .bot .message-content ul,
.life-coach-session-component .session-message-component .bot .message-content li {
    background-color: var(--color__darkGreen);
    color: lightgrey;
    display: inline-block;
    padding: 4px 15px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    font-size: 2.3rem;
    white-space: break-spaces;
}

.life-coach-session-component .session-message-component .bot .message-content ul li::before {
    content: "\2022";
    /* Unicode character for a bullet */
    color: lightgrey;
    /* Color of the bullet */
    font-weight: bold;
    /* Optional: make the bullet more prominent */
    display: inline-block;
    width: 1em;
    /* Width of the bullet, providing consistent spacing */
    margin-left: -1em;
    /* Move the bullet to the left */
}

.life-coach-session-component .session-message-component .bot .message-content a {
    text-decoration: underline;
    font-size: 2.3rem;
}

.life-coach-session-component .session-message-component .message {
    display: flex;
}