.login-register {
    background-color: #F2F7FA;
}

.login-register .login-acc {
    min-height: 55vh;
}

@media screen and (max-width: 767px) {
    .login-register {
        padding: 7rem 1rem;
    }
}

@media screen and (min-width: 768px) {
    .login-register {
        padding: 7rem 0 8rem;
    }
}

@media screen and (min-width: 1200px) {
    .login-register {
        padding: 7rem 0 10rem;
    }
}

@media screen and (min-width: 768px) {
    .login-register .login-acc {
        padding: 6rem;
        box-shadow: 0 0 1.5rem 0 rgba(17, 17, 17, 0.1);
        position: relative;
    }
}

@media screen and (min-width: 992px) {
    .login-register .login-acc {
        padding: 0;
    }
}

.login-register .row {
    --bs-gutter-x: 0;
}

@media screen and (max-width: 991px) {
    .login-register .row {
        --bs-gutter-y: 5rem;
    }
}

@media screen and (min-width: 992px) {
    .login-register .form-inner {
        padding: 6rem 4rem;
    }
}

@media screen and (min-width: 1200px) {
    .login-register .form-inner {
        padding: 6rem;
    }
}

.login-register .form-title {
    margin-bottom: 3rem;
}

.login-register .side-form {
    background-color: var(--color__darkGreen);
    height: 100%;
    padding: 3rem 0;
}

.login-register .form-group+.form-group {
    margin-top: 2rem;
}

.login-register .form-group label {
    margin-bottom: 0.5rem;
}

.login-register .custom-check label {
    padding-left: 2.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.login-register .custom-check label::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2rem;
    height: 2rem;
    background-color: var(--color__white);
    border-radius: 0.3rem;
    border: 0.1rem solid var(--color__darkGreen);
    transition: all 0.3s;
}

.login-register .custom-check label::after {
    content: "";
    position: absolute;
    left: 0.4rem;
    width: 1.2rem;
    height: 0.6rem;
    border-bottom: 0.2rem solid var(--color__white);
    border-left: 0.2rem solid var(--color__white);
    transform: rotate(-45deg);
    margin-bottom: 0.2rem;
    opacity: 0;
    transition: all 0.3s;
}

.login-register .custom-check input {
    display: none;
}

.login-register .custom-check input:checked~label::before {
    background-color: var(--color__darkGreen);
}

.login-register .custom-check input:checked~label::after {
    opacity: 1;
}

.login-register button[type=submit] {
    width: 100%;
    border: 0;
    background-color: var(--color__yellow);
    padding: 1rem 0;
    border-radius: 0.5rem;
}

.login-register .forgot-password {
    margin-left: auto;
}

.login-register .forgot-password a {
    cursor: pointer;
}

.login-register .password-container {
    position: relative;
}

.login-register .password-container .eye-img {
    position: absolute;
    right: 1rem;
    top: 1.3rem;
    cursor: pointer;
    width: 2rem;
}

.login-register .facebook-button-class {
    display: block;
    border: 0px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: black;
    cursor: pointer;
    font-size: 19px;
    margin: 5px;
    width: calc(100% - 10px);
    overflow: hidden;
    padding: 0px 10px;
    user-select: none;
    height: 50px;
    background: white;
    margin-top: 1rem;
}

.login-register .facebook-button-class svg {
    float: left;
    color: rgba(23, 113, 237, 1);
}

.login-register .phone-input {
    font-size: 3rem;
    text-align: center;
    width: 6rem;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .login-register .phone-input {
        font-size: 2.5rem;
        width: 5rem;
    }
}

.login-register .all-set-container {
    margin-top: 5rem;
}

@media screen and (max-width: 768px) {
    .login-register .all-set-container {
        margin-top: 0rem;
    }
}

.login-register .account-optional-action {
    margin-top: 4rem;
}

.login-register .btn-yellow {
    background-color: var(--color__yellow);
    border: 0.1rem solid var(--color__yellow);
    color: var(--color__darkGreen);
    font-family: "Open Sans", sans-serif;
}

.login-register .btn-yellow:hover {
    background-color: var(--color__yellow);
    color: var(--color__white);
    border-color: var(--color__yellow);
}

.login-notification {
    background-color: transparent !important;
}

.no-shadown {
    box-shadow: none !important;
}

.login-notification-btn-skip {
    background-color: transparent;
    color: gray;
    font-family: "Gotham Rounded";
    font-size: 14px;
    border: none;
    width: 30%;
    padding: 10px 20px;
}

.login-notification-btn-skip:hover {
    background-color: rgba(197, 197, 197, 0.479);
    border-radius: 6px;
}


.login-notification-btn-enable {
    background-color: var(--color__yellow);
    border: 2px solid rgba(165, 165, 165, 0.397);
    padding: 10px 20px;
    font-family: "Gotham Rounded";
    font-size: 16px;
    color: white;
    border-radius: 6px;
    width: 30%;
}
