.red-ribbon-component {
    position: relative;
    background-color: rgba(255, 100, 100, 0.85);
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

@media screen and (min-width: 991px) {
    .red-ribbon-component {
        margin-right: 300px;
    }
}


/* Center the text vertically and horizontally */

.red-ribbon-component {
    display: flex;
    align-items: center;
    justify-content: center;
}