.badge-component-in-popup .earned-component {
    width: 200px;
    text-align: center;
    padding: 1rem 0rem 1rem 0rem;
    background-color: var(--color__darkGreen);
    color: white;
    border-radius: 3rem;
}

.badge-component-in-popup .earned-component .earned-date {
    font-size: 1.2rem;
    margin-top: 1rem;
}